export const CAROUSEL_EPISODES_LIMIT = 10;

export const Modals = {
  ONBOARDING: 'ONBOARDING',
  BROWSER_AUTOPLAY_PROHIBITED: 'browserAutoPlayProhibited',
  REDEEM: 'REDEEM',
  ASSESSMENT: 'ASSESSMENT',
  CPE_DOWNLOAD_PROGRESS: 'cpeDownloadProgress',
  CPA_SIGNUP: 'CPA_SIGNUP',
  CPA_CONTENT_END: 'CPA_CONTENT_END',
  TRACKER_ADD_EDIT: 'TRACKER_ADD_EDIT',
  TRACKER_DELETE: 'TRACKER_DELETE',
  SIGN_UP: 'SIGN_UP',
  ETHICS_LOCKED: 'ETHICS_LOCKED',
  PREMIUM_LOCKED: 'PREMIUM_LOCKED',
  INVALID_PLAN: 'INVALID_PLAN',
  PLAN_USED_UP: 'PLAN_USED_UP',
  EPISODE_EVALUATION: 'EPISODE_EVALUATION',
  EPISODE_FEEDBACK: 'EPISODE_FEEDBACK',
  PRODUCT_FEEDBACK: 'PRODUCT_FEEDBACK',
  PURCHASE: 'PURCHASE',
  APP_DOWNLOAD: 'APP_DOWNLOAD',
  CHAPTER_QUIZ: 'CHAPTER_QUIZ',
  MEMBER_ARCHIVE: 'MEMBER_ARCHIVE',
} as const;
