import { Box, Theme, Tooltip, useMediaQuery } from '@mui/material';
import { useMemo } from 'react';

import { Button } from '@/components';
import { DownloadIcon } from '@/icons';
import { AnalyticsService, EVENTS } from '@/services';
import { OrganizationMember } from '@/types';

import { rowsToInlineCsv } from './utils';

export type CsvDownloadProps = {
  data: OrganizationMember[] | undefined;
};

export const CsvDownload = ({ data }: CsvDownloadProps) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );
  const trackDownloadButtonClick = () => {
    AnalyticsService.logEvent(EVENTS.MEMBER_MANAGEMENT_EVENTS.DOWNLOAD_CSV);
  };
  const csvData = useMemo(() => {
    const rowData = [
      [
        'Name',
        'Member Email',
        'Login Email',
        'Access',
        'Last Listened (UTC)',
        'Date Added (UTC)',
      ],
      ...(data?.map(c => [
        c.name ? `"${c.name.replaceAll('"', '""')}"` : '',
        c.organizationEmail ?? '',
        c.userEmail ?? '',
        c.state ?? '',
        c.lastUserListening ?? '',
        c.organizationMemberCreatedAt ?? '',
      ]) ?? []),
    ];

    return rowsToInlineCsv(rowData);
  }, [data]);

  return (
    <Box>
      <Tooltip title="Download CSV with all Members">
        {/** span needed for tooltip to attach to */}
        <span>
          <Button
            label={isMobile ? '' : 'CSV'}
            aria-label="Download CSV"
            component="a"
            onClick={trackDownloadButtonClick}
            startIcon={<DownloadIcon />}
            disabled={!data?.length}
            href={csvData}
            download="lumiq-members.csv"
            fullWidth={false}
          />
        </span>
      </Tooltip>
    </Box>
  );
};
