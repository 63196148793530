import { HeadphonesOutlined, MailOutlineRounded } from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';

import { MemberActivityTypes } from '@/constants';
import { OrganizationMember } from '@/types';

import { capitalizeFirstLetter, timeSince, utcToLocalDateTime } from './utils';

export type Props = {
  activityType: OrganizationMember['lastActivityType'];
  date: OrganizationMember['lastActivityDate'];
};

export const UserFriendlyDateWithTooltip = ({ activityType, date }: Props) => {
  if (activityType === MemberActivityTypes.NONE) {
    return <Typography variant="body3">-</Typography>;
  }

  let label = '';
  let Icon = null;
  let color = 'black';
  if (activityType === MemberActivityTypes.LISTENING) {
    Icon = HeadphonesOutlined;
    label = date ? capitalizeFirstLetter(timeSince(date)) : 'No usage';
    color = date ? 'black' : 'salmon';
  } else if (activityType === MemberActivityTypes.INVITED) {
    Icon = MailOutlineRounded;
    label = `Invited ${timeSince(date)}`;
  } else if (activityType === MemberActivityTypes.REMOVED) {
    label = `Removed ${timeSince(date)}`;
  }

  return (
    <Tooltip title={date ? utcToLocalDateTime(date) : ''}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 0.5,
        }}>
        {Icon ? <Icon sx={{ width: 16, height: 16 }} /> : null}
        <Typography variant="body3" color={color}>
          {label}
        </Typography>
      </Box>
    </Tooltip>
  );
};
