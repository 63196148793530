import { Checkbox, TableCell, TableRow, Typography } from '@mui/material';
import { format } from 'date-fns';
import { memo } from 'react';

import { OrganizationMember } from '@/types';

import { MemberState } from './MemberState';
import { UserFriendlyDateWithTooltip } from './UserFriendlyDateWithTooltip';

interface MemberRowProps {
  toggleSelectMember: (organizationMemberId: number) => void;
  isSelected: boolean;
  isDisabled: boolean;
  isManagedOrg: boolean;
  organizationMemberId: OrganizationMember['organizationMemberId'];
  name: OrganizationMember['name'];
  organizationEmail: OrganizationMember['organizationEmail'];
  userEmail: OrganizationMember['userEmail'];
  state: OrganizationMember['state'];
  lastActivityDate: OrganizationMember['lastActivityDate'];
  lastActivityType: OrganizationMember['lastActivityType'];
  organizationMemberCreatedAt: OrganizationMember['organizationMemberCreatedAt'];
}

export const MemberRow = memo(
  ({
    organizationMemberId,
    toggleSelectMember,
    isDisabled,
    isSelected,
    isManagedOrg,
    name,
    organizationEmail,
    userEmail,
    state,
    lastActivityDate,
    lastActivityType,
    organizationMemberCreatedAt,
  }: MemberRowProps) => {
    return (
      <TableRow
        key={organizationMemberId}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
        }}>
        <TableCell
          padding="checkbox"
          onClick={
            !isDisabled
              ? () => toggleSelectMember(organizationMemberId)
              : undefined
          }
          role="checkbox"
          aria-label={`${name} ${isSelected ? 'selected' : 'not selected'}`}
          tabIndex={-1}
          sx={
            !isDisabled
              ? {
                  cursor: 'pointer',
                  '&:hover .MuiCheckbox-root': {
                    backgroundColor: 'rgba(0, 169, 178, 0.04)',
                  },
                }
              : {}
          }>
          <Checkbox
            color="primary"
            disabled={isDisabled}
            checked={isSelected}
          />
        </TableCell>
        <TableCell component="th" scope="row" sx={{ paddingBlock: 2 }}>
          <Typography variant="body2" fontWeight="bold" lineHeight="30px">
            {name}
          </Typography>
          <Typography variant="body3">{organizationEmail}</Typography>
          {userEmail && organizationEmail !== userEmail && (
            <Typography variant="body3">
              {userEmail}{' '}
              <Typography variant="body3" component="span" color="GrayText">
                (login email)
              </Typography>
            </Typography>
          )}
        </TableCell>
        <TableCell sx={{ paddingBlock: 2 }}>
          <MemberState
            state={state}
            isManagedOrg={isManagedOrg}
            organizationMemberId={organizationMemberId}
          />
        </TableCell>
        <TableCell sx={{ paddingBlock: 2 }}>
          <UserFriendlyDateWithTooltip
            activityType={lastActivityType}
            date={lastActivityDate}
          />
        </TableCell>
        <TableCell sx={{ paddingBlock: 2 }}>
          <Typography variant="body3">
            {format(organizationMemberCreatedAt, 'MMM d, yyyy')}
          </Typography>
        </TableCell>
      </TableRow>
    );
  },
);
