import { Box, Typography } from '@mui/material';
import { useInView } from 'react-intersection-observer';

import { Routes } from '@/constants';
import { CreditsDurationInfo, EpisodeCount, Link } from '@/containers';
import { AnalyticsService } from '@/services';
import { theme } from '@/theme';
import { LearningPathWithDuration, Referrer } from '@/types';
import { createRouteWithParams } from '@/utils';

export const LP_COVER_WIDTH_SMALL = 130;
export const LP_COVER_WIDTH_LARGE = 200;

export const LearningPathCard = ({
  learningPath,
  isHorizontalVariant = false,
  referrer,
}: {
  learningPath: LearningPathWithDuration;
  isHorizontalVariant?: boolean;
  referrer?: Referrer;
}) => {
  const { ref } = useInView({
    threshold: 0,
    triggerOnce: true,
    onChange: inView => {
      if (inView) {
        AnalyticsService.learningPathImpression({
          learningPathId: learningPath.groupId,
          location: referrer,
        });
      }
    },
  });

  const episodeCount = learningPath.episodes.length || 0;

  const progressPercent =
    Math.min(learningPath.durationPlayed / learningPath.duration, 1) * 100;
  const imageWidth = isHorizontalVariant
    ? LP_COVER_WIDTH_SMALL
    : LP_COVER_WIDTH_LARGE;
  const radius = imageWidth / 2;
  const stroke = isHorizontalVariant ? 6 : 10;
  const strokeGap = 2;
  const offsetImageWidth =
    progressPercent > 0 ? imageWidth - (stroke + strokeGap) * 2 : imageWidth;
  const normalizedRadius = radius - stroke / 2;
  const strokeColor = theme.palette.blue30;
  const circumference = 2 * Math.PI * normalizedRadius;
  const strokeDashoffset =
    circumference - (progressPercent / 100) * circumference;

  return (
    <Link
      variant="plain"
      to={createRouteWithParams(Routes.LEARNING_PATH, {
        learningPathId: learningPath.groupId,
      })}
      state={{
        referrer,
      }}>
      <Box
        ref={ref}
        sx={[
          styles.root,
          {
            width: isHorizontalVariant ? 315 : imageWidth,
            flexDirection: isHorizontalVariant ? 'row' : 'column',
          },
        ]}>
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={[
              styles.progressContainer,
              { visibility: progressPercent > 0 ? 'visible' : 'hidden' },
            ]}>
            <svg height={radius * 2} width={radius * 2}>
              <circle
                cx={radius}
                cy={radius}
                fill="none"
                r={normalizedRadius}
                stroke={strokeColor}
                strokeDasharray={`${circumference}, ${circumference}`}
                strokeWidth={stroke}
                style={{ strokeDashoffset }}
                transform={`rotate(90, ${radius}, ${radius})`}
              />
            </svg>
          </Box>
          <Box
            sx={{
              position: 'relative',
              width: offsetImageWidth,
              height: offsetImageWidth,
              m: `${progressPercent > 0 ? stroke + strokeGap : 0}px`,
              overflow: 'hidden',
              borderRadius: '50%',
            }}>
            <Box
              sx={styles.coverImage}
              component="img"
              src={learningPath.coverImage}
              alt="learning path cover"
            />
            <Box sx={styles.imageOverlay} />
          </Box>
        </Box>
        <Box sx={styles.textContainer}>
          <Box sx={styles.topRow}>
            <CreditsDurationInfo
              credits={learningPath.credits}
              duration={learningPath.duration}
              durationPlayed={learningPath.durationPlayed}
            />
            <EpisodeCount episodeCount={episodeCount} />
          </Box>
          <Typography variant="h5">{learningPath.title}</Typography>
        </Box>
      </Box>
    </Link>
  );
};

const styles = {
  root: {
    display: 'flex',
    gap: 1.5,
    '&:hover img': { transform: 'scale(1.1)' },
  },
  progressContainer: {
    backgroundColor: 'neutral10',
    borderRadius: '50%',
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  coverImage: {
    transition: 'transform .25s ease-in-out',
    height: '100%',
    transform: 'scale(1.005)',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
    justifyContent: 'center',
  },
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: 1,
    flexWrap: 'wrap',
  },
  imageOverlay: {
    position: 'absolute',
    top: 0,
    backgroundColor: 'blue30',
    opacity: 0.05,
    height: '100%',
    width: '100%',
  },
};
