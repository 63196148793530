import { ExpandMoreRounded, RefreshRounded } from '@mui/icons-material';
import { Box, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';

import { MemberStates, Modals } from '@/constants';
import { AnalyticsService, EVENT_CONSTANTS, EVENTS } from '@/services';
import {
  uiActions,
  useAppDispatch,
  useReInviteOrganizationMembersMutation,
} from '@/store';
import { PALETTE } from '@/theme';
import { OrganizationMember } from '@/types';
import { SnackbarUtils } from '@/utils';

export type Props = {
  state: OrganizationMember['state'];
  isManagedOrg: boolean;
  organizationMemberId: OrganizationMember['organizationMemberId'];
};

export const MemberState = ({
  state,
  isManagedOrg,
  organizationMemberId,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>();

  let label: string = state;
  let color: string = PALETTE.red;
  switch (state.toLowerCase()) {
    case MemberStates.NEW:
    case MemberStates.USER_CLAIMED:
      label = 'Unassigned';
      color = PALETTE.red;
      break;
    case MemberStates.PLAN_ASSIGNED:
      label = 'Invited';
      color = PALETTE.gold;
      break;
    case MemberStates.ACTIVE:
      label = 'Enrolled';
      color = PALETTE.teal30;
      break;
    case MemberStates.ARCHIVED:
    case MemberStates.USER_DELETED:
      label = 'Removed';
      color = PALETTE.neutral20;
      break;
  }

  const dispatch = useAppDispatch();
  const [reinviteOrganizationMembers] =
    useReInviteOrganizationMembersMutation();

  const resendInvite = async () => {
    AnalyticsService.resendInvite({
      location: {
        page: EVENT_CONSTANTS.PAGE.MEMBER_MANAGEMENT,
        button: EVENT_CONSTANTS.BUTTON.RESEND,
        component: EVENT_CONSTANTS.COMPONENT.TABLE_ROW,
      },
      organizationMemberIds: [organizationMemberId],
    });

    try {
      AnalyticsService.logEvent(EVENTS.MEMBER_MANAGEMENT_EVENTS.RESEND.INTENT);

      setAnchorEl(null);
      await reinviteOrganizationMembers([organizationMemberId]).unwrap();

      AnalyticsService.logEvent(EVENTS.MEMBER_MANAGEMENT_EVENTS.RESEND.SUCCESS);
      SnackbarUtils.success('New invitation has been sent');
    } catch (error) {
      AnalyticsService.logEvent(EVENTS.MEMBER_MANAGEMENT_EVENTS.RESEND.FAIL);
      SnackbarUtils.warning('Failed to resend the invitation, try again later');
    }
  };

  const isArchivingDisabled =
    isManagedOrg ||
    state === MemberStates.ARCHIVED ||
    state === MemberStates.USER_DELETED;

  return (
    <>
      <Box onClick={e => setAnchorEl(e.currentTarget)} sx={styles.root}>
        <Typography variant="h4" lineHeight="18px" color={color}>
          &bull;&nbsp;
        </Typography>
        <Typography variant="body3" id="status-label">
          {label}
        </Typography>
        {(state === MemberStates.ACTIVE ||
          state === MemberStates.NEW ||
          state === MemberStates.USER_CLAIMED) && (
          <ExpandMoreRounded sx={{ height: 20, width: 20 }} />
        )}
        {state === MemberStates.PLAN_ASSIGNED && (
          <RefreshRounded sx={{ height: 15, width: 20 }} />
        )}
      </Box>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        disableScrollLock
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          disablePadding: true,
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: 2,
            },
          },
        }}>
        {state === MemberStates.PLAN_ASSIGNED && (
          <MenuItem sx={styles.menuItem} onClick={resendInvite}>
            <Typography variant="body3">Resend Invite</Typography>
          </MenuItem>
        )}
        <Tooltip
          placement="right"
          title={
            isManagedOrg
              ? 'Members in your organization are managed by a third party integration.'
              : ''
          }>
          <MenuItem
            sx={styles.menuItem}
            disabled={isArchivingDisabled}
            onClick={() => {
              if (isArchivingDisabled) return;
              AnalyticsService.removeMember({
                location: {
                  page: EVENT_CONSTANTS.PAGE.MEMBER_MANAGEMENT,
                  component: EVENT_CONSTANTS.COMPONENT.TABLE_ROW,
                  button: EVENT_CONSTANTS.BUTTON.REMOVE,
                },
                organizationMemberIds: [organizationMemberId],
              });
              setAnchorEl(null);
              dispatch(
                uiActions.setActiveModal({
                  name: Modals.MEMBER_ARCHIVE,
                  params: {
                    organizationMemberIds: [organizationMemberId],
                    referrer: {
                      page: EVENT_CONSTANTS.PAGE.MEMBER_MANAGEMENT,
                      button: EVENT_CONSTANTS.BUTTON.REMOVE,
                    },
                  },
                }),
              );
            }}>
            <Typography variant="body3">Remove</Typography>
          </MenuItem>
        </Tooltip>
      </Menu>
    </>
  );
};

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'fit-content',
    cursor: 'pointer',
    borderBottom: '1.5px solid transparent',
    '&:hover #status-label': {
      textDecoration: 'underline',
    },
  },
  menuItem: {
    py: 1.5,
    px: 2,
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
  },
};
