import {
  MemberSortColumn,
  MemberSortDirection,
  OrganizationMember,
} from '@/types';

export const timeSince = (
  stringDate: string | number | Date | null,
  smallestPossibleUnit = 'minute',
) => {
  if (stringDate === null) {
    return '';
  }
  const date = new Date(stringDate);

  // If the date is invalid, return '-'
  if (isNaN(date.getTime())) {
    return '-';
  }

  const now = new Date();
  const diffMs = now.getTime() - date.getTime();

  // Calculate different time units
  const years = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 365.25));
  const months = Math.floor(
    (diffMs % (1000 * 60 * 60 * 24 * 365.25)) / (1000 * 60 * 60 * 24 * 30.44),
  );
  const days = Math.floor(
    (diffMs % (1000 * 60 * 60 * 24 * 30.44)) / (1000 * 60 * 60 * 24),
  );
  const hours = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

  if (years > 0) {
    if (years >= 1 && years < 2) {
      return 'last year';
    }
    return `${years} year${years !== 1 ? 's' : ''} ago`;
  }
  if (smallestPossibleUnit === 'year') {
    return 'this year';
  }

  if (months > 0) {
    if (months >= 1 && months < 2) {
      return 'last month';
    }
    return `${months} month${months !== 1 ? 's' : ''} ago`;
  }
  if (smallestPossibleUnit === 'month') {
    return 'this month';
  }

  if (days > 0) {
    if (days >= 1 && days < 2) {
      return 'yesterday';
    }
    return `${days} day${days !== 1 ? 's' : ''} ago`;
  }
  if (smallestPossibleUnit === 'day') {
    return 'today';
  }

  if (hours > 0) {
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  }
  if (smallestPossibleUnit === 'hour') {
    return 'just now';
  }

  if (minutes > 0) {
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  }

  return 'just now';
};

export const capitalizeFirstLetter = (str: string) => {
  const firstChar = str.charAt(0);
  if (/[a-z]/.test(firstChar)) {
    return firstChar.toUpperCase() + str.slice(1);
  }
  return str;
};

export const utcToLocalDate = (utcDate: string) => {
  return new Date(utcDate).toLocaleDateString();
};

export const utcToLocalDateTime = (utcDate: string) => {
  return new Date(utcDate).toLocaleString();
};

export const rowsToInlineCsv = (rows: (string | number | boolean)[][]) => {
  let csvContent = '';

  rows.forEach(rowArray => {
    let row = rowArray.join(',');
    csvContent += row + '\r\n';
  });

  const universalBOM = '\uFEFF';
  return `data:text/csv; charset=utf-8,${encodeURIComponent(universalBOM + csvContent)}`;
};

export const searchMembers = (
  searchPhrase: string,
  data: OrganizationMember[],
) => {
  if (searchPhrase.trim() === '') {
    return data;
  }

  return data.filter(m =>
    [m.name, m.userEmail, m.organizationEmail].some(v =>
      v?.toString().toLowerCase().includes(searchPhrase.trim().toLowerCase()),
    ),
  );
};

export const sortMembersFactory =
  (sortColumn: MemberSortColumn, sortDirection: MemberSortDirection) =>
  (a: OrganizationMember, b: OrganizationMember) => {
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];

    if (aValue === null || aValue === undefined) return 1;
    if (bValue === null || bValue === undefined) return -1;

    const comparison = aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
    return (
      comparison *
      (sortDirection === 'asc' ? 1 : -1) *
      (sortColumn === 'lastActivityDate' ? -1 : 1)
    );
  };
