import {
  Bookmark,
  BookmarkBorder,
  ConfirmationNumberRounded,
  Lock,
} from '@mui/icons-material';
import { Box, Hidden, Theme, Typography } from '@mui/material';
import { useState } from 'react';

import {
  Button,
  CardFlair,
  DurationPill,
  EthicsPill,
  Skeleton,
} from '@/components';
import { Routes } from '@/constants';
import { PlayArrowFilledIcon, PlayArrowOutlinedIcon } from '@/icons';
import { EpisodeCard, Referrer } from '@/types';
import { createRouteWithParams } from '@/utils';

import { Link } from '../Link';

export const EpisodeBanner = ({
  episode,
  onTrailer,
  onPlayNow,
  onRedeem,
  toggleBookmark,
  isBookmarked,
  isEpisodeEthics,
  isEpisodePremium,
  isEpisodeRedeemRequired,
  referrer,
}: {
  episode: EpisodeCard;
  onTrailer: () => void;
  onPlayNow: () => void;
  onRedeem: () => void;
  toggleBookmark: () => void;
  isBookmarked: boolean;
  isEpisodeEthics: boolean;
  isEpisodePremium: boolean;
  isEpisodeRedeemRequired: boolean;
  referrer: Referrer;
}) => {
  const [coverLoaded, setCoverLoaded] = useState(false);

  const PlayButton = () => {
    if (isEpisodePremium) {
      return (
        <Button
          variant="premium"
          onClick={e => {
            e?.preventDefault();
            onPlayNow();
          }}
          disabled={episode.isArchived}
          label="Premium"
          skinny={true}
          fullWidth={false}
          startIcon={<Lock fontSize="small" />}
        />
      );
    }

    return isEpisodeRedeemRequired ? (
      <Button
        label="Redeem"
        variant="gradientPrimary"
        onClick={e => {
          e?.preventDefault();
          onRedeem();
        }}
        skinny={true}
        fullWidth={false}
        startIcon={<ConfirmationNumberRounded />}
      />
    ) : (
      <Button
        variant="gradientPrimary"
        onClick={e => {
          e?.preventDefault();
          onPlayNow();
        }}
        disabled={episode.isArchived}
        label="Play"
        skinny={true}
        fullWidth={false}
        startIcon={<PlayArrowFilledIcon />}
      />
    );
  };

  return (
    <Box sx={styles.root}>
      <Box
        id="episode-cover-img"
        component="img"
        alt="episode cover"
        src={episode.cover}
        onLoad={() => setCoverLoaded(true)}
        sx={[styles.coverImage, { display: coverLoaded ? 'block' : 'none' }]}
      />
      {!coverLoaded && <Skeleton variant="rounded" />}
      <Link
        to={createRouteWithParams(Routes.EPISODE, {
          episodeId: episode.episodeId,
        })}
        state={{ referrer }}
        color="inherit">
        <Box sx={styles.gradientContainer}>
          {!!episode.companyLogo && (
            <Box
              component="img"
              src={episode.companyLogo}
              alt="company logo"
              sx={styles.companyLogo}
            />
          )}
          <Box sx={styles.cardBody}>
            <Box sx={styles.textContainer}>
              <Box sx={styles.pillContainer}>
                <DurationPill duration={episode.duration} color="white" />
                {isEpisodeEthics && <EthicsPill color="white" />}
              </Box>
              <Typography variant="h3" sx={styles.text}>
                {episode.name}
              </Typography>
              <Hidden mdDown>
                <Typography variant="body3" sx={styles.text}>
                  {episode.hook}
                </Typography>
              </Hidden>
            </Box>
            <Box sx={styles.buttons}>
              <PlayButton />
              <Button
                variant="tertiary"
                label="Preview"
                fullWidth={false}
                skinny={true}
                startIcon={<PlayArrowOutlinedIcon />}
                colorOverride="white"
                onClick={e => {
                  e?.preventDefault();
                  onTrailer();
                }}
                disabled={episode.isArchived}
              />
              {isBookmarked ? (
                <Bookmark
                  onClick={e => {
                    e.preventDefault();
                    toggleBookmark();
                  }}
                  sx={styles.bookmarkIcon}
                />
              ) : (
                <BookmarkBorder
                  onClick={e => {
                    e.preventDefault();
                    toggleBookmark();
                  }}
                  sx={styles.bookmarkIcon}
                />
              )}
            </Box>
          </Box>
          {episode.episodeAgeTag === 'new' && (
            <CardFlair variant="large" label="NEW!" />
          )}
        </Box>
      </Link>
    </Box>
  );
};

const styles = {
  root: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 4,
    height: (theme: Theme) => theme.dimensions.episodeBanner.height,
    width: (theme: Theme) => theme.dimensions.episodeBanner.width,
    color: 'text.white',
    display: 'flex',
    justifyContent: 'center',
    '&:hover #episode-cover-img': {
      transform: 'scale(1.1)',
    },
  },
  coverImage: {
    width: '100%',
    objectFit: 'cover',
    transform: 'scale(1.005)',
    transition: 'transform .25s ease-in-out',
  },
  gradientContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    background: {
      xs: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.65) 73%)',
      md: 'linear-gradient(215deg, rgba(0, 0, 0, 0.00) 45%, rgba(0, 0, 0, 0.30) 60%, rgba(0, 0, 0, 0.65) 77%)',
    },
    p: 3,
  },
  companyLogo: {
    backgroundColor: 'white',
    borderRadius: 2,
    height: 70,
    width: 70,
    boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.65)',
  },
  cardBody: {
    display: 'flex',
    flexDirection: 'column',
  },
  textContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    width: { xs: '100%', md: '50%' },
    gap: 0.5,
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 5,
    WebkitBoxOrient: 'vertical',
  },
  pillContainer: {
    display: 'flex',
    gap: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttons: { display: 'flex', alignItems: 'flex-end', gap: 1, mt: 2 },
  bookmarkIcon: {
    cursor: 'pointer',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderRadius: '50%',
    ml: 'auto',
    width: 32,
    height: 32,
    p: 0.5,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
    },
  },
};
