import {
  Attachment,
  CloudDownload,
  ExpandMoreRounded,
  MenuBook,
} from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TruncatedBox } from '@/components';
import { Modals } from '@/constants';
import { Link } from '@/containers';
import { AnalyticsService } from '@/services';
import {
  selectEpisodeRegion,
  selectSessionUserId,
  uiActions,
  useAppSelector,
} from '@/store';

import { CPEDetailsModal } from '../';

const FILE_EXTENSION_REGEX = /\.\w+$/;

export const AdditionalResources = ({ episode }) => {
  const { resources, visualAid, CPEDetails } = episode;
  const visualAidUrl = visualAid?.horizontalPdf?.url;

  const dispatch = useDispatch();

  const userId = useSelector(selectSessionUserId);
  const region = useAppSelector(state =>
    selectEpisodeRegion(state, episode?.episodeId),
  );

  const [showCPEDetails, setShowCPEDetails] = useState(false);

  if (
    !resources?.length &&
    !visualAidUrl &&
    !(region === 'USA' && !!CPEDetails)
  )
    return null;

  const onVisualAidClick = () => {
    if (!userId) {
      dispatch(uiActions.setActiveModal({ name: Modals.SIGN_UP }));
      return;
    }
    window.open(visualAidUrl);
    AnalyticsService.visualAidView({
      episodeId: episode.episodeId,
    });
  };

  const onResourceClick = resource => {
    if (!userId) {
      dispatch(uiActions.setActiveModal({ name: Modals.SIGN_UP }));
      return;
    }
    window.open(resource.file.url);
    AnalyticsService.resourceView({
      resourceName: resource.name,
      episodeId: episode.episodeId,
    });
  };

  const onCPEDetailsClick = () => setShowCPEDetails(true);

  /*
    Sort resources to put Episode Transcript at the top and sort the rest alphabetically.
    Some resources are labelled 'Episode Transcription' instead, so do a substring check instead of an exact match.
  */
  const formattedResources = [...resources]
    .sort((a, b) => {
      if (a.name.includes('Episode Transcript')) return -1;
      if (b.name.includes('Episode Transcript')) return 1;
      return a.name.localeCompare(b.name);
    })
    .map(resource => ({
      ...resource,
      extension: resource.file.url.match(FILE_EXTENSION_REGEX)?.[0],
    }));

  return (
    <>
      <TruncatedBox
        ExpandIcon={({ isTruncated }) => (
          <ExpandMoreRounded
            sx={{
              fontSize: 46,
              transition: 'transform 0.2s',
              transform: isTruncated ? null : 'rotate(180deg)',
            }}
          />
        )}>
        <Typography variant="h3">Additional Resources</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
          {visualAidUrl && (
            <Box sx={styles.resourceContainer}>
              <Link onClick={onVisualAidClick}>
                <Box sx={styles.resourceLink}>
                  <MenuBook sx={styles.resourceIcon} />
                  <Typography variant="button2">Visual Aid</Typography>
                </Box>
              </Link>
            </Box>
          )}
          {region === 'USA' && !!CPEDetails && (
            <Box sx={styles.resourceContainer}>
              <Link onClick={onCPEDetailsClick}>
                <Box sx={styles.resourceLink}>
                  <Attachment sx={styles.resourceIcon} />
                  <Typography variant="button2">
                    CPE/QAS Requirements
                  </Typography>
                </Box>
              </Link>
            </Box>
          )}
          {formattedResources?.map((resource, index) => (
            <Box key={index} sx={styles.resourceContainer}>
              <Link onClick={() => onResourceClick(resource)}>
                <Box sx={styles.resourceLink}>
                  {resource.extension ? (
                    <Typography
                      sx={[styles.fileExtensionIcon, styles.resourceIcon]}>
                      {resource.extension}
                    </Typography>
                  ) : (
                    <CloudDownload sx={styles.resourceIcon} />
                  )}
                  <Typography variant="button2">{resource.name}</Typography>
                </Box>
              </Link>
            </Box>
          ))}
        </Box>
      </TruncatedBox>
      <CPEDetailsModal
        isOpened={showCPEDetails}
        setOpened={setShowCPEDetails}
        CPEDetails={CPEDetails}
      />
    </>
  );
};

const styles = {
  resourceContainer: {
    my: 1.5,
    mx: 2,
  },
  resourceLink: {
    display: 'flex',
    alignItems: 'center',
  },
  resourceIcon: {
    mr: 1,
  },
  fileExtensionIcon: {
    fontSize: '8px !important',
    fontWeight: '600 !important',
    color: 'white',
    backgroundColor: 'blue30',
    width: 24,
    height: 24,
    borderRadius: 1,
    textAlign: 'center',
    flexShrink: 0,
  },
};
