import { MemberFilters as MemberFiltersType, MemberSortColumn } from '@/types';

export const MemberStates = {
  NEW: 'new',
  USER_CLAIMED: 'user_claimed',
  PLAN_ASSIGNED: 'plan_assigned',
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  USER_DELETED: 'user_deleted',
} as const;

export const MemberActivityTypes = {
  LISTENING: 'listening',
  INVITED: 'invited',
  REMOVED: 'removed',
  NONE: 'none',
} as const;

export const MemberFilters = {
  ALL_NON_ARCHIVED: {
    key: 'ALL_NON_ARCHIVED',
    label: 'All Members',
    text: 'All Members',
    tooltip: 'Show all members',
    states: [
      MemberStates.ACTIVE,
      MemberStates.PLAN_ASSIGNED,
      MemberStates.NEW,
      MemberStates.USER_CLAIMED,
    ],
  },
  INVITED: {
    key: 'INVITED',
    label: 'Invited',
    text: 'Invited',
    tooltip: 'Only show invited members',
    states: [MemberStates.PLAN_ASSIGNED],
  },
  REMOVED: {
    key: 'REMOVED',
    label: 'Removed',
    text: 'Removed',
    tooltip: 'Only show removed members',
    states: [MemberStates.ARCHIVED, MemberStates.USER_DELETED],
  },
} as MemberFiltersType;

export const MemberSortColumns: {
  name: string;
  width?: string | number;
  sortKey: MemberSortColumn;
}[] = [
  { name: 'Name', sortKey: 'name' },
  { name: 'Access', sortKey: 'state' },
  { name: 'Activity', sortKey: 'lastActivityDate' },
  {
    name: 'Added',
    sortKey: 'organizationMemberCreatedAt',
  },
];
