import { Modals } from '@/constants';

import { AppModal } from '../AppModal';
import { MemberArchiveModalContent } from './MemberArchiveModalContent';

export const MemberArchiveModal = () => {
  return (
    <AppModal modalName={Modals.MEMBER_ARCHIVE} maxWidth="xs">
      <MemberArchiveModalContent />
    </AppModal>
  );
};
