import mixpanel, { Mixpanel } from 'mixpanel-browser';

import config from '@/config';
import { urls } from '@/constants';
import { ConfigResponse, getIsEpisodeEthics } from '@/store';
import {
  AnalyticEventProps,
  AssessmentEventAttemptProps,
  BookmarkEventProps,
  ClickThroughProps,
  Episode,
  EpisodeDetailSwitchTabProps,
  EpisodeEvaluationEventProps,
  EpisodeEventProps,
  EpisodeFeedbackEventProps,
  FilterEventProps,
  LearningPathEventProps,
  Location,
  MemberManagementEventProps,
  PauseEventProps,
  PlaybackSpeedChangedEventProps,
  ProductFeedbackEventProps,
  QuizAttemptEventProps,
  Region,
  ResourceViewEventProps,
  SeekEventProps,
  SkipEventProps,
  TechnicalSearchEventProps,
  UserProfile,
  VideoPlayerFullScreenEventProps,
  VolumeEventProps,
} from '@/types';
import { ageInHours, convertSecondsToHoursMinutes } from '@/utils';

import { growthbook } from '../features';
import { ParamObject, sessionConfig, validateParamValue } from './config';
import { EVENT_CONSTANTS, EVENTS } from './events';

let userProperties: Record<
  string,
  boolean | undefined | string | number | Date
> = {};
let userTags = {};
let featureFlags: Record<string, string> = {};
const queuedEvents: Array<{
  eventName: string;
  data: Record<string, ParamObject>;
}> = []; // Queue of events that fire before config is completed

interface InitProps {
  userId?: string;
  playerConfig?: ConfigResponse;
  userProfile?: UserProfile;
  userRegion?: Region;
  organizationName?: string;
  userTags?: string[];
}

export function initialize({
  userId,
  playerConfig,
  userProfile,
  userRegion,
  organizationName,
  userTags: tags,
}: InitProps) {
  mixpanel.init(config.MIX_PANEL_PROJECT_TOKEN, {
    api_host: urls.actions.mixpanel,
    loaded(mp) {
      sessionConfig.checkSessionId();
      const originalTrack = mp.track;
      mp.track = function (...args: Parameters<Mixpanel['track']>) {
        sessionConfig.checkSessionId();
        mp.register({ last_event_time: Date.now() });
        originalTrack.apply(mp, args);
      };
    },
  });

  userProperties = {
    $distinct_id: userId,
    nasba: userRegion === 'USA',
    $email: userProfile?.email,
    ...(organizationName ? { organizationName } : {}),
    ...(userProfile
      ? {
          $name: `${userProfile.firstName} ${userProfile.lastName}`,
          signupDate: userProfile.userCreatedAt,
          onboardingDate: userProfile.onboardingCompletedAt,
        }
      : {}),
    ...Object.fromEntries(
      Object.entries(playerConfig || {}).map(([key, value]) => [
        `player_${key}`,
        value,
      ]),
    ),
  };
  userTags =
    tags?.reduce(
      (obj, tag: string) => {
        const [key, value] = tag.split(':');
        const fieldName = `$user_tag - ${key}`;
        return { ...obj, [fieldName]: [...(obj[fieldName] || []), value] };
      },
      {} as Record<string, string[]>,
    ) || {};

  if (userId) {
    mixpanel.identify(userId);
    mixpanel.people.set(userProperties);
    mixpanel.people.set(userTags);
  }

  while (queuedEvents.length) {
    const { eventName, data } =
      queuedEvents.shift() as (typeof queuedEvents)[number];
    logEvent(eventName, data);
  }
}

export const updateUserProfile = ({ name, email }: typeof userProperties) => {
  userProperties = {
    ...userProperties,
    $name: name,
    $email: email,
  };
  mixpanel.people.set(userProperties);
};

export const updateFeatureFlags = (flags: object) => {
  featureFlags = { ...featureFlags, ...flags };
};

/* Events */

export const pageLoad = ({ queryParams = {}, campaignId = null } = {}) => {
  logEvent(EVENTS.PAGE_EVENTS.LOAD, {
    queryParams,
    campaignId: validateParamValue(campaignId || ''),
  });
};

export const pageView = (location: Location) => {
  logEvent(EVENTS.PAGE_EVENTS.VIEW, {
    location: validateParamValue(location || ''),
  });
};

export const pageViewEpisode = (episode: Episode) => {
  logEvent(EVENTS.PAGE_EVENTS.VIEW_EPISODE, {
    episodeId: episode.episodeId,
    episodeName: episode.name,
    episodeFormat: episode.isVideo ? 'video' : 'audio',
    episodeDuration: episode.duration,
    formattedEpisodeDuration: convertSecondsToHoursMinutes(episode.duration),
    episodeChapters: episode.chapters?.length,
    episodeTypes: [
      ...(episode.isRecordedLive ? ['live'] : []),
      ...(episode.isPremium ? ['premium'] : []),
      ...(getIsEpisodeEthics({
        episode,
        userRegion: userProperties.nasba ? 'USA' : 'CAN',
      })
        ? ['ethics']
        : []),
    ],
  });
};

export const clickThrough = ({
  target,
  referrer,
  section,
  resourceId,
}: ClickThroughProps) => {
  logEvent(EVENTS.CLICK_THROUGH, {
    target,
    location: validateParamValue(referrer || ''),
    section,
    resourceId,
  });
};

export function chapterPlay({
  chapterId,
  episodeId,
  location,
  referrer,
}: AnalyticEventProps) {
  logEvent(EVENTS.CHAPTER_EVENTS.PLAY, {
    chapterId,
    episodeId,
    location: validateParamValue(location || ''),
    referrer: validateParamValue(referrer || ''),
  });
}

export function chapterPause({
  chapterId,
  episodeId,
  location,
  referrer,
  pausing,
}: PauseEventProps) {
  logEvent(
    pausing ? EVENTS.CHAPTER_EVENTS.PAUSE : EVENTS.CHAPTER_EVENTS.RESUME,
    {
      chapterId,
      episodeId,
      location: validateParamValue(location || ''),
      referrer: validateParamValue(referrer || ''),
    },
  );
}

export function chapterSeek({
  alreadyListened,
  chapterId,
  episodeId,
  seekStart,
  seekLength,
  type,
}: SeekEventProps) {
  logEvent(EVENTS.CHAPTER_EVENTS.SEEK, {
    alreadyListened,
    chapterId,
    episodeId,
    seekStart,
    seekLength,
    type: validateParamValue(type || ''),
  });
}

export function chapterStart({
  chapterId,
  episodeId,
  location,
  referrer,
}: AnalyticEventProps) {
  logEvent(EVENTS.CHAPTER_EVENTS.START, {
    chapterId,
    episodeId,
    location: validateParamValue(location || ''),
    referrer: validateParamValue(referrer || ''),
  });
}

export function chapterComplete({ chapterId, episodeId }: AnalyticEventProps) {
  logEvent(EVENTS.CHAPTER_EVENTS.COMPLETE, {
    chapterId,
    episodeId,
  });
}

export function playbackSpeedChanged({
  speed,
  chapterId,
  episodeId,
  location,
}: PlaybackSpeedChangedEventProps) {
  logEvent(EVENTS.CHAPTER_EVENTS.PLAYBACK_SPEED_CHANGED, {
    speed,
    chapterId,
    episodeId,
    location: validateParamValue(location || ''),
  });

  logEvent(EVENTS.TRACK_PLAYER_EVENTS.CHANGE_SPEED, {
    speed,
    chapterId,
    episodeId,
    location: validateParamValue(location || ''),
  });
}

export function trackPlayerPlayPause({
  chapterId,
  episodeId,
  location,
  referrer,
  pausing,
}: PauseEventProps) {
  logEvent(
    pausing
      ? EVENTS.TRACK_PLAYER_EVENTS.PAUSE
      : EVENTS.TRACK_PLAYER_EVENTS.PLAY_RESUME,
    {
      chapterId,
      episodeId,
      location: validateParamValue(location || ''),
      referrer: validateParamValue(referrer || ''),
    },
  );
}

export function trackPlayerSeek({
  alreadyListened,
  chapterId,
  episodeId,
  seekStart,
  seekLength,
  type,
  location,
}: SeekEventProps) {
  logEvent(EVENTS.TRACK_PLAYER_EVENTS.CHANGE_POSITION, {
    alreadyListened,
    chapterId,
    episodeId,
    seekStart,
    seekLength,
    type: validateParamValue(type || ''),
    location: validateParamValue(location || ''),
  });
}

export function trackPlayerVolume({
  chapterId,
  episodeId,
  val,
  location,
}: VolumeEventProps) {
  logEvent(EVENTS.TRACK_PLAYER_EVENTS.ADJUST_VOLUME, {
    chapterId,
    episodeId,
    volume: val,
    location: validateParamValue(location || ''),
  });

  if (val <= 0) {
    logEvent(EVENTS.TRACK_PLAYER_EVENTS.MUTE, {
      chapterId,
      episodeId,
      volume: val,
      location: validateParamValue(location || ''),
    });
  }
}

export function trackPlayerSkip({
  chapterId,
  episodeId,
  direction,
}: SkipEventProps) {
  logEvent(EVENTS.TRACK_PLAYER_EVENTS.SKIP_CHAPTER, {
    chapterId,
    episodeId,
    skipDirection: direction,
  });
}

export function trackPlayerRewind({
  chapterId,
  episodeId,
  location,
}: AnalyticEventProps) {
  logEvent(EVENTS.TRACK_PLAYER_EVENTS.REWIND, {
    chapterId,
    episodeId,
    location: validateParamValue(location || ''),
  });
}

export function trackPlayerViewEpisode({
  chapterId,
  episodeId,
  location,
  referrer,
}: AnalyticEventProps) {
  logEvent(EVENTS.TRACK_PLAYER_EVENTS.VIEW_EPISODE_DETAILS, {
    chapterId,
    episodeId,
    location: validateParamValue(location || ''),
    referrer: validateParamValue(referrer || ''),
  });
}

export function trackPlayerFeedback({
  chapterId,
  episodeId,
}: AnalyticEventProps) {
  logEvent(EVENTS.TRACK_PLAYER_EVENTS.EPISODE_FEEDBACK, {
    chapterId,
    episodeId,
  });
}

export function removeMember({
  organizationMemberIds,
  location,
  referrer,
}: MemberManagementEventProps) {
  logEvent(EVENTS.MEMBER_MANAGEMENT_EVENTS.REMOVE_MEMBER, {
    location: validateParamValue(location || ''),
    referrer: validateParamValue(referrer || ''),
    organizationMemberIds,
  });
}

export function resendInvite({
  organizationMemberIds,
  location,
  referrer,
}: MemberManagementEventProps) {
  logEvent(EVENTS.MEMBER_MANAGEMENT_EVENTS.RESEND_INVITE, {
    location: validateParamValue(location || ''),
    referrer: validateParamValue(referrer || ''),
    organizationMemberIds,
  });
}

export function videoPlayerFullScreen({
  chapterId,
  episodeId,
  isFullScreen,
}: VideoPlayerFullScreenEventProps) {
  logEvent(EVENTS.VIDEO_PLAYER_EVENTS.FULLSCREEN, {
    chapterId,
    episodeId,
    isFullScreen,
  });
}

// UNUSED - Dev only?
export function debug(eventName: string, data: {}) {
  logEvent(EVENTS.DEBUG, { eventName, ...data });
}

export function episodeStart({
  chapterId,
  episodeId,
  location,
  referrer,
}: AnalyticEventProps) {
  logEvent(EVENTS.EPISODE_EVENTS.START, {
    chapterId,
    episodeId,
    location: validateParamValue(location || ''),
    referrer: validateParamValue(referrer || ''),
  });
}

export function episodeListened({ episodeId }: EpisodeEventProps) {
  logEvent(EVENTS.EPISODE_EVENTS.LISTENED, {
    episodeId,
  });
}

export function episodeComplete({ episodeId }: EpisodeEventProps) {
  logEvent(EVENTS.EPISODE_EVENTS.COMPLETE, {
    episodeId,
  });
}

export function episodeImpression({
  episodeId,
  location,
  referrer,
}: EpisodeEventProps) {
  logEvent(EVENTS.EPISODE_EVENTS.IMPRESSION, {
    episodeId,
    location: validateParamValue(location || ''),
    referrer: validateParamValue(referrer || ''),
  });
}

export function learningPathImpression({
  learningPathId,
  location,
}: LearningPathEventProps) {
  logEvent(EVENTS.LEARNING_PATH_EVENTS.IMPRESSION, {
    learningPathId,
    location: validateParamValue(location || ''),
  });
}

export function episodeRedeem({ episodeId }: EpisodeEventProps) {
  logEvent(EVENTS.EPISODE_EVENTS.REDEEM, {
    episodeId,
  });
}

export function episodeShare({ episodeId, location }: EpisodeEventProps) {
  logEvent(EVENTS.EPISODE_EVENTS.SHARE, {
    episodeId,
    location: validateParamValue(location || ''),
  });
}

export function bookmark({
  episodeId,
  value,
  location,
  referrer,
}: BookmarkEventProps) {
  logEvent(EVENTS.EPISODE_EVENTS.BOOKMARK, {
    episodeId,
    value,
    location: validateParamValue(location || ''),
    referrer: validateParamValue(referrer || ''),
  });

  if (location?.component === EVENT_CONSTANTS.COMPONENT.TRACK_PLAYER) {
    logEvent(EVENTS.TRACK_PLAYER_EVENTS.BOOKMARK, {
      episodeId,
      value,
      location: validateParamValue(location || ''),
      referrer: validateParamValue(referrer || ''),
    });
  }
}

export function episodeFeedback({
  episodeRating,
  episodeId,
  chapterId,
  chapterProgress,
  episodePlayedDuration,
  textFeedback,
  ratingReasons,
}: EpisodeFeedbackEventProps) {
  logEvent(EVENTS.EPISODE_FEEDBACK_EVENTS.FEEDBACK, {
    episodeRating,
    chapterId,
    chapterProgress,
    episodePlayedDuration,
    episodeId,
    ratingReasons: validateParamValue(ratingReasons || ''),
    textFeedback: validateParamValue(textFeedback || ''),
  });
}

export function productFeedback({
  productRating,
  isDismissed,
  textFeedback,
  referrer,
}: ProductFeedbackEventProps) {
  logEvent(EVENTS.PRODUCT_FEEDBACK, {
    productRating: validateParamValue(productRating || ''),
    isDismissed,
    referrer: validateParamValue(referrer || ''),
    textFeedback: validateParamValue(textFeedback || ''),
  });
}

export function episodeEvaluation({
  enjoyedPodcast,
  episodeId,
  textFeedback,
  answers,
}: EpisodeEvaluationEventProps) {
  logEvent(EVENTS.EPISODE_FEEDBACK_EVENTS.EVALUATION, {
    enjoyedPodcas: !!enjoyedPodcast,
    episodeId,
    textFeedback,
    answers,
  });
}

export function technicalSearchViewEpisode({
  episodeId,
  location,
}: EpisodeEventProps) {
  logEvent(EVENTS.TECHNICAL_SEARCH_EVENTS.VIEW_EPISODE, {
    episodeId,
    location: validateParamValue(location || ''),
  });
}

export function episodeDetailSwitchTab({
  episodeId,
  tabName,
}: EpisodeDetailSwitchTabProps) {
  logEvent(EVENTS.EPISODE_DETAIL_EVENTS.SWITCH_TAB, {
    episodeId,
    tabName,
  });
}

export function visualAidView({ episodeId }: EpisodeEventProps) {
  logEvent(EVENTS.VISUAL_AID_VIEW, {
    episodeId,
  });
}

export function resourceView({
  resourceName,
  episodeId,
  chapterId,
}: ResourceViewEventProps) {
  if (episodeId) {
    logEvent(EVENTS.RESOURCE_VIEW, {
      resourceName,
      episodeId,
    });
  } else if (chapterId) {
    logEvent(EVENTS.RESOURCE_VIEW, {
      resourceName,
      chapterId,
    });
  } else {
    logEvent(EVENTS.RESOURCE_VIEW, { resourceName });
  }
}

export function quizAttempt({
  chapterId,
  quizId,
  answer,
  pass,
}: QuizAttemptEventProps) {
  logEvent(EVENTS.QUIZ_EVENTS.ATTEMPT, {
    chapterId,
    quizId,
    answer,
    pass: !!pass,
  });
  logEvent(pass ? EVENTS.QUIZ_EVENTS.PASS : EVENTS.QUIZ_EVENTS.FAIL, {
    chapterId,
    quizId,
    answer,
    pass: !!pass,
  });
}

export function assessmentAttempt({
  episodeId,
  assessmentId,
  answer,
  score,
  pass,
}: AssessmentEventAttemptProps) {
  const assessmentDetails = {
    episodeId,
    assessmentId,
    answer,
    score,
    pass,
  };

  logEvent(EVENTS.ASSESSMENT_EVENTS.ATTEMPT, assessmentDetails);
  logEvent(
    pass ? EVENTS.ASSESSMENT_EVENTS.PASS : EVENTS.ASSESSMENT_EVENTS.FAIL,
    assessmentDetails,
  );
}

export function filterLibrary({
  filters,
  categoriesFilter,
  fieldsOfStudyFilter,
}: FilterEventProps) {
  logEvent(EVENTS.FILTER_LIBRARY_EVENTS.FILTER, {
    categoriesFilter,
    fieldsOfStudyFilter,
    sortBy: filters.sortBy,
    mediaType: filters.format,
    hidePlayed: filters.hidePlayed,
    showEthics: filters.onlyEthics,
  });
}

export function filterLibraryClear({
  categoryName,
}: {
  categoryName: string | undefined;
}) {
  logEvent(
    categoryName === EVENT_CONSTANTS.CLEAR_ALL
      ? EVENTS.FILTER_LIBRARY_EVENTS.FILTER_CLEAR
      : EVENTS.FILTER_LIBRARY_EVENTS.FILTER_REMOVE,
    { categoryName: validateParamValue(categoryName || '') },
  );
}

export function technicalSearchFilter({
  selectedRegions,
  selectedCategory,
  selectedTopic,
}: TechnicalSearchEventProps) {
  logEvent(EVENTS.TECHNICAL_SEARCH_EVENTS.FILTER, {
    selectedRegions,
    selectedCategory,
    selectedTopic,
  });
}

/* Local Functions */

export function showModal(name: string) {
  logEvent(EVENTS.MODAL_EVENTS.OPEN, { modalName: name });
}

export function closeModal(name: string) {
  logEvent(EVENTS.MODAL_EVENTS.CLOSE, { modalName: name });
}

export function buttonClick({ name }: { name: string }) {
  logEvent(EVENTS.BUTTON_CLICK, { buttonName: name });
}

export function logEvent(
  eventName: string,
  data: Record<string, ParamObject> = {},
) {
  // if (!mixpanel.config) {
  if (!Object.keys(userProperties).length) {
    queuedEvents.push({ eventName, data });
    return;
  }

  const blacklist = growthbook
    .getFeatureValue('event-blacklist', '')
    .split('|');

  if (blacklist.includes(eventName)) {
    console.info('Skipping blacklisted event:', eventName);
    return;
  }

  const { referrer, ...otherData } = data;
  const payload: typeof userProperties = {
    ...(otherData || {}),
    ...userProperties,
    ...userTags,
    ...Object.entries(featureFlags).reduce(
      (obj, [key, value]) => ({
        ...obj,
        [`$feature - ${key}`]: value,
      }),
      {},
    ),
  };

  if (payload.onboardingDate) {
    payload.onboardingAge = ageInHours(userProperties.onboardingDate as string);
  }

  if (payload.signupDate) {
    payload.signupAge = ageInHours(userProperties.signupDate as string);
  }

  if (
    process.env.NODE_ENV === 'development' &&
    localStorage.getItem('analyticsConsoleLogs')
  ) {
    console.info(eventName, payload);
  }
  mixpanel.track(eventName, payload);
}
