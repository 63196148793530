import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { TableSearchBox } from '@/components';
import { MemberFilters, MemberSortColumns, urls } from '@/constants';
import {
  selectUserProfileResult,
  useFetchOrganizationMembersQuery,
  useFetchOrganizationSettingsQuery,
} from '@/store';
import {
  MemberFilterKey,
  MemberSortColumn,
  MemberSortDirection,
} from '@/types';

import { BulkEdit } from './BulkEdit';
import { CsvDownload } from './CsvDownload';
import { MemberRow } from './MemberRow';
import { MemberStateFilters } from './MemberStateFilters';
import { SkeletonTable } from './SkeletonTable';
import { searchMembers, sortMembersFactory } from './utils';

export const MemberManagement = () => {
  const { isLoading: isProfileLoading, data: profileData } = useSelector(
    selectUserProfileResult,
  );
  const { data: members, isLoading } = useFetchOrganizationMembersQuery(null);
  const { data: organizationSettings } = useFetchOrganizationSettingsQuery();

  const [activeFilter, setActiveFilter] = useState<MemberFilterKey>(
    MemberFilters.ALL_NON_ARCHIVED.key,
  );
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [sortColumn, setSortColumn] = useState<MemberSortColumn>('name');
  const [sortDirection, setSortDirection] =
    useState<MemberSortDirection>('asc');
  const [selectedOrganizationMemberIds, setSelectedOrganizationMemberIds] =
    useState<number[]>([]);
  const numSelectedOrganizationMembers = selectedOrganizationMemberIds.length;

  const filteredData = useMemo(() => {
    if (!members) return [];
    const stateFilteredData = members.filter(m =>
      MemberFilters[activeFilter].states.includes(m.state),
    );
    const searchFilteredData = searchMembers(searchPhrase, stateFilteredData);
    return [...searchFilteredData].sort(
      sortMembersFactory(sortColumn, sortDirection),
    );
  }, [members, searchPhrase, activeFilter, sortColumn, sortDirection]);

  const isAllSelected =
    numSelectedOrganizationMembers > 0 &&
    numSelectedOrganizationMembers === filteredData.length;

  const toggleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOrganizationMemberIds(
      event.target.checked
        ? filteredData.map(item => item.organizationMemberId)
        : [],
    );
  };

  const toggleSelectMember = useCallback((organizationMemberId: number) => {
    setSelectedOrganizationMemberIds(prevState =>
      prevState.includes(organizationMemberId)
        ? prevState.filter(id => id !== organizationMemberId)
        : [...prevState, organizationMemberId],
    );
  }, []);

  useEffect(() => {
    setSelectedOrganizationMemberIds([]);
  }, [members]);

  if (
    !isProfileLoading &&
    !profileData?.memberRoles?.some(r => r.roleId === 'member_admin')
  ) {
    return (
      <Box minHeight="60vh">
        <Typography variant="h1" gutterBottom color="red">
          No Access
        </Typography>
        <Typography variant="body2">
          Please contact{' '}
          <a href={`mailto:${urls.supportEmail}`}>LumiQ support</a> if you
          should have access
        </Typography>
      </Box>
    );
  }

  const handleSort = (column: MemberSortColumn) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  return (
    <Box>
      <Box sx={styles.toolbar}>
        <Box sx={styles.toolbarLeft}>
          <Typography variant="h1">Members</Typography>
          <MemberStateFilters
            activeFilter={activeFilter}
            onFilterSelection={filter => {
              setSelectedOrganizationMemberIds([]);
              setActiveFilter(filter);
            }}
            data={members}
          />
        </Box>
        <Box sx={styles.toolbarRight}>
          <TableSearchBox
            placeHolder="Search Members"
            onSearch={search => {
              setSearchPhrase(search);
            }}
          />
          <CsvDownload data={members} />
        </Box>
      </Box>
      <Box sx={styles.table}>
        <TableContainer>
          <Table sx={{ minWidth: 800 }} aria-label="Organization Members">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    disabled={
                      isLoading || activeFilter === MemberFilters.REMOVED.key
                    }
                    checked={isAllSelected}
                    onChange={toggleSelectAll}
                  />
                </TableCell>
                {MemberSortColumns.map(c => (
                  <TableCell
                    key={c.name}
                    width={c?.width}
                    sx={{ paddingBlock: 2 }}>
                    <TableSortLabel
                      active={sortColumn === c.sortKey}
                      direction={sortDirection}
                      onClick={() => handleSort(c.sortKey)}>
                      <Typography
                        variant="body1"
                        lineHeight="30px"
                        fontWeight={700}>
                        {c.name}
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* TODO: empty state */}
              {isLoading ? (
                <SkeletonTable rows={5} />
              ) : (
                filteredData?.map(row => (
                  <MemberRow
                    key={row.organizationMemberId}
                    organizationMemberId={row.organizationMemberId}
                    toggleSelectMember={toggleSelectMember}
                    isDisabled={activeFilter === MemberFilters.REMOVED.key}
                    isSelected={
                      isAllSelected ||
                      selectedOrganizationMemberIds.includes(
                        row.organizationMemberId,
                      )
                    }
                    isManagedOrg={!!organizationSettings?.isManagedOrg}
                    name={row.name}
                    organizationEmail={row.organizationEmail}
                    userEmail={row.userEmail}
                    state={row.state}
                    lastActivityDate={row.lastActivityDate}
                    lastActivityType={row.lastActivityType}
                    organizationMemberCreatedAt={
                      row.organizationMemberCreatedAt
                    }
                  />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <BulkEdit
        members={members ?? []}
        setSelectedOrganizationMemberIds={setSelectedOrganizationMemberIds}
        selectedOrganizationMemberIds={selectedOrganizationMemberIds}
        isArchivingDisabled={!!organizationSettings?.isManagedOrg}
      />
    </Box>
  );
};

const styles = {
  toolbar: () => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: { xs: 'wrap' },
    gap: 1,
    mb: 1,
  }),
  toolbarLeft: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  toolbarRight: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  table: () => ({
    mb: 4,
  }),
};
