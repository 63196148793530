import { Dialog, DialogProps } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AnalyticsService } from '@/services';
import { selectActiveModal, uiActions } from '@/store';

export const AppModal = ({
  children,
  modalName,
  disableBackdropClick,
  onClose,
  maxWidth,
  ...muiDialogProps
}: {
  children: React.ReactNode;
  modalName: string;
  disableBackdropClick?: boolean;
  onClose?: () => void;
} & Omit<DialogProps, 'onClose' | 'open'>) => {
  const dispatch = useDispatch();
  const activeModal = useSelector(selectActiveModal);
  const isOpen = activeModal?.name === modalName;

  const onMuiDialogClose = (
    event: object,
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (disableBackdropClick && reason === 'backdropClick') return;
    onClose?.();
    dispatch(uiActions.closeActiveModal());
  };

  useEffect(() => {
    if (isOpen) {
      AnalyticsService.showModal(modalName);
    }
  }, [modalName, isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={onMuiDialogClose}
      maxWidth={maxWidth}
      {...muiDialogProps}
      PaperProps={{
        sx: {
          color: 'text.black',
        },
      }}>
      {children}
    </Dialog>
  );
};
