import { ALL_REGION, ALL_TOPIC, CAROUSEL_EPISODES_LIMIT } from '@/constants';
import {
  AnswerQuizPayload,
  ClaimPlayerSessionPayload,
} from '@/store/player-fsm';
import { Region } from '@/types';

import {
  EPISODE_CARD_REQUIRED_FIELDS,
  PostgrestEndpoints,
} from './postgrest.constants';
import {
  FetchCategoriesParams,
  FetchCategoryEpisodesParams,
  FetchEpisodeParams,
  FetchEpisodesParams,
  FetchLibraryEpisodesParams,
  FetchNewEpisodesParams,
  PauseSessionParams,
  PlaySessionParams,
  PostSeekPositionParams,
  PostSetPositionParams,
} from './postgrest.types';

export const refreshAccessTokenRequest = ({
  refreshToken,
}: {
  refreshToken: string;
}) => ({
  url: PostgrestEndpoints.REFRESH_ACCESS_TOKEN,
  method: 'POST',
  body: {
    refresh_token: refreshToken,
  },
});

export const fetchFeaturedEpisodesRequest = ({
  region = 'CAN',
}: {
  region: Region;
}) => ({
  url: PostgrestEndpoints.FEATURED_EPISODES,
  params: {
    region: `eq.${region === 'CAN' ? 'canada' : 'usa'}`,
    is_archived: 'is.false',
    select: EPISODE_CARD_REQUIRED_FIELDS.join(','),
    // TODO do we need episodes: 'not.is.null' here?
  },
});

export const fetchTrendingEpisodesRequest = ({
  region = 'CAN',
}: {
  region: Region;
}) => ({
  url: PostgrestEndpoints.TRENDING_EPISODES,
  params: {
    region: `eq.${region === 'CAN' ? 'canada' : 'usa'}`,
    is_archived: 'is.false',
    select: EPISODE_CARD_REQUIRED_FIELDS.join(','),
    // TODO do we need episodes: 'not.is.null' here?
  },
});

export const fetchNewEpisodesRequest = ({
  isTechnical,
  region = 'CAN',
}: FetchNewEpisodesParams) => ({
  url: PostgrestEndpoints.EPISODE,
  params: {
    region: `eq.${region === 'CAN' ? 'canada' : 'usa'}`,
    order: 'publish_date.desc',
    is_archived: 'is.false',
    select: EPISODE_CARD_REQUIRED_FIELDS.join(','),
    limit: CAROUSEL_EPISODES_LIMIT,
    is_technical: `is.${!!isTechnical}`,
  },
});

export const fetchOrganizationPlaylistEpisodes = ({
  region,
}: {
  region: Region;
}) => ({
  url: PostgrestEndpoints.ORGANIZATION_PLAYLIST,
  params: {
    region: `eq.${region === 'CAN' ? 'canada' : 'usa'}`,
    is_archived: 'is.false',
  },
});

export const fetchLibraryEpisodesRequest = ({
  filters,
  region = 'CAN',
}: FetchLibraryEpisodesParams) => ({
  url: PostgrestEndpoints.EPISODE,
  params: {
    select: EPISODE_CARD_REQUIRED_FIELDS.join(','),
    is_archived: 'is.false',
    region: `eq.${region === 'CAN' ? 'canada' : 'usa'}`,
    ...filters,
  },
});

export const fetchCategoryEpisodesRequest = ({
  categoryId,
  region,
  fieldsOfStudy,
}: FetchCategoryEpisodesParams) => ({
  url: PostgrestEndpoints.EPISODE,
  params: {
    is_archived: 'is.false',
    region: `eq.${region === 'CAN' ? 'canada' : 'usa'}`, // TODO discuss, right now if you don't pass region you get duplicates
    ...(fieldsOfStudy
      ? { field_of_study_id: `in.(${fieldsOfStudy?.join(',')})` }
      : { categories: `cs.{${categoryId}}` }),
    order: 'publish_date.desc',
    select: EPISODE_CARD_REQUIRED_FIELDS.join(','),
    limit: CAROUSEL_EPISODES_LIMIT,
  },
});

export const fetchEpisodeRequest = ({
  episodeId,
  region,
  includeArchived = false,
}: FetchEpisodeParams) => ({
  url: PostgrestEndpoints.EPISODE,
  params: {
    episode_id: `eq.${episodeId}`,
    ...(region ? { region: `eq.${region === 'CAN' ? 'canada' : 'usa'}` } : {}),
    ...(includeArchived ? {} : { is_archived: 'is.false' }),
  },
});

export const fetchEpisodesRequest = ({
  episodeIds,
  region,
  includeArchived = false,
}: FetchEpisodesParams) => ({
  url: PostgrestEndpoints.EPISODE,
  params: {
    episode_id: `in.(${episodeIds.join(',')})`,
    select: EPISODE_CARD_REQUIRED_FIELDS.join(','),
    ...(region ? { region: `eq.${region === 'CAN' ? 'canada' : 'usa'}` } : {}),
    ...(includeArchived ? {} : { is_archived: 'is.false' }),
  },
});

export const fetchCategoriesRequest = ({
  categoryIds,
}: FetchCategoriesParams = {}) => ({
  url: PostgrestEndpoints.CATEGORIES,
  params: {
    region: 'eq.canada',
    ...(categoryIds
      ? { original_category_id: `in.(${categoryIds.join(',')})` }
      : {}),
  },
});

export const fetchFieldsOfStudyRequest = () => ({
  url: PostgrestEndpoints.FIELDS_OF_STUDY,
});

export const fetchLearningPathsRequest = ({ region }: { region: Region }) => ({
  url: PostgrestEndpoints.LEARNING_PATHS,
  params: {
    region: `eq.${region === 'CAN' ? 'canada' : 'usa'}`,
  },
});

export const fetchLearningPathRequest = ({
  region,
  learningPathId,
}: {
  region: Region;
  learningPathId: Number;
}) => ({
  url: PostgrestEndpoints.LEARNING_PATHS,
  params: {
    region: `eq.${region === 'CAN' ? 'canada' : 'usa'}`,
    group_id: `eq.${learningPathId}`,
  },
});

export const fetchChapterRequest = ({
  chapterId,
  region,
}: {
  chapterId: string;
  region: Region;
}) => {
  return {
    url: PostgrestEndpoints.CHAPTER,
    params: {
      firestore_chapter_id: `eq.${chapterId}`,
      region: `eq.${region === 'CAN' ? 'canada' : 'usa'}`,
    },
  };
};

export const fetchEpisodeQuizzesRequest = ({
  episodeId,
  region,
}: {
  episodeId: string;
  region: Region;
}) => ({
  url: PostgrestEndpoints.QUIZ,
  params: {
    episode_id: `eq.${episodeId}`,
    region: `eq.${region === 'CAN' ? 'canada' : 'usa'}`,
  },
});

export const fetchUserAssessmentRequest = ({
  episodeId,
}: {
  episodeId: string;
}) => ({
  method: 'POST',
  url: PostgrestEndpoints.USER_ASSESSMENT,
  body: {
    episode_id: episodeId,
    create_if_not_exists: true,
  },
});

export const fetchChapterContentRequest = ({
  episodeId,
}: {
  episodeId: string;
}) => ({
  url: PostgrestEndpoints.CHAPTER_URL,
  params: {
    episode_id: `eq.${episodeId}`,
  },
});

export const fetchTrailerContentRequest = ({
  episodeId,
}: {
  episodeId: string;
}) => ({
  url: PostgrestEndpoints.TRAILER_URL,
  params: {
    episode_id: `eq.${episodeId}`,
  },
});

export const fetchEpisodeChaptersRequest = ({
  episodeId,
  region,
}: {
  episodeId: string;
  region: Region;
}) => ({
  url: PostgrestEndpoints.CHAPTER,
  params: {
    episode_id: `eq.${episodeId}`,
    region: `eq.${region === 'CAN' ? 'canada' : 'usa'}`,
  },
});

export const fetchDesignationOptionsRequest = () => ({
  url: PostgrestEndpoints.DESIGNATION_OPTIONS,
});

export const sendAccountLinkingEmailRequest = ({
  email,
}: {
  email: string;
}) => ({
  url: PostgrestEndpoints.SEND_ACCOUNT_LINKING_EMAIL,
  method: 'POST',
  body: {
    email,
  },
});

export const updateUserDesignationsRequest = ({
  userDesignations,
}: {
  userDesignations: unknown[];
}) => ({
  url: PostgrestEndpoints.UPDATE_USER_DESIGNATIONS,
  method: 'POST',
  body: userDesignations,
});

export const finalizeRegistrationRequest = () => ({
  url: PostgrestEndpoints.FINALIZE_REGISTRATION,
  method: 'POST',
});

export const fetchTechnicalSearchOptionsRequest = ({
  optionType,
}: {
  optionType: string;
}) => ({
  url: PostgrestEndpoints.TECHNICAL_SEARCH_OPTIONS,
  params: {
    ...(optionType ? { type: `eq.${optionType}` } : {}),
  },
});

export const fetchTechnicalSearchResultRequest = ({
  selectedCategory = null,
  selectedRegions = [],
  selectedTopic = null,
  userRegion,
}: {
  selectedCategory?: unknown;
  selectedRegions?: string[];
  selectedTopic?: unknown;
  userRegion: unknown;
}) => ({
  url: PostgrestEndpoints.TECHNICAL_SEARCH_RESULT,
  method: 'POST',
  body: {
    categories: selectedCategory,
    regions: selectedRegions[ALL_REGION as keyof typeof selectedRegions]
      ? null
      : Object.keys(selectedRegions).join(','),
    topics: selectedTopic === ALL_TOPIC ? null : selectedTopic,
    only_nasba_content: userRegion === 'USA',
  },
});

export const fetchPDHoursRequest = ({
  dateRange,
}: {
  dateRange: {
    fromDate: string;
    toDate: string;
  };
}) => ({
  url: PostgrestEndpoints.CREDITS,
  params: {
    order: 'date_completed.desc',
    ...(dateRange.fromDate &&
      dateRange.toDate && {
        and: `(date_completed.gte.${dateRange.fromDate},date_completed.lte.${dateRange.toDate})`,
      }),
    ...(dateRange.fromDate &&
      !dateRange.toDate && {
        date_completed: `gte.${dateRange.fromDate}`,
      }),
    ...(dateRange.toDate &&
      !dateRange.fromDate && {
        date_completed: `lte.${dateRange.toDate}`,
      }),
  },
});

export const fetchCPACanadaEpisodeRequest = () => ({
  url: PostgrestEndpoints.CPA_CANADA_EPISODE,
});

export const addPaymentMethod = ({ token }: { token: string }) => ({
  url: PostgrestEndpoints.ADD_PAYMENT_METHOD,
  method: 'POST',
  body: { payment_method_id: token },
});

export const createCharge = ({
  sku,
  province,
}: {
  sku: string;
  province: string;
}) => ({
  url: PostgrestEndpoints.CREATE_CHARGE,
  method: 'POST',
  body: {
    sku,
    tax_region: province,
  },
});

export const getPaymentActionResult = ({
  asyncResultId,
}: {
  asyncResultId: string;
}) => ({
  url: PostgrestEndpoints.GET_PAYMENT_ACTION_RESULT,
  params: { async_result_id: `eq.${asyncResultId}` },
});

export const getPaymentMethods = () => ({
  url: PostgrestEndpoints.GET_PAYMENT_METHODS,
  params: { brand: 'neq.null' },
});

export const getOffers = ({ skus }: { skus: string[] }) => ({
  url: PostgrestEndpoints.OFFERS,
  params: {
    sku: `in.(${skus.join(',')})`,
    active: 'is.true',
  },
});

export const fetchOrder = ({ orderId }: { orderId: number }) => ({
  url: PostgrestEndpoints.MY_ORDERS,
  params: {
    order_id: `eq.${orderId}`,
  },
});

export const getMyOffers = () => ({
  url: PostgrestEndpoints.MY_OFFERS,
});

export const fetchTaxRates = () => ({
  url: PostgrestEndpoints.TAX_RATES,
});

export const fetchPlayerConfig = () => ({
  url: PostgrestEndpoints.PLAYER_CONFIG,
});

export const fetchSession = ({ episodeId }: { episodeId: string }) => ({
  url: PostgrestEndpoints.FIND_PLAYER_SESSION,
  params: {
    episode_id: episodeId,
  },
});

export const resumeLastPlayerSession = () => ({
  url: PostgrestEndpoints.RESUME_LAST_PLAYER_SESSION,
  method: 'POST',
});

export const startPlayerSession = ({ episodeId }: { episodeId: string }) => ({
  url: PostgrestEndpoints.START_PLAYER_SESSION,
  method: 'POST',
  body: {
    episode_id: episodeId,
  },
});

export const playPlayerSession = ({
  sessionId,
  chapterId,
  position,
}: PlaySessionParams) => ({
  url: PostgrestEndpoints.PLAY_PLAYER_SESSION,
  method: 'POST',
  body: {
    session_id: sessionId,
    chapter_id: chapterId,
    position: position && Math.floor(position),
    verbose: true,
  },
});

export const pausePlayerSession = ({
  sessionId,
  chapterId,
  position,
}: PauseSessionParams) => ({
  url: PostgrestEndpoints.PAUSE_PLAYER_SESSION,
  method: 'POST',
  body: {
    session_id: sessionId,
    chapter_id: chapterId,
    position: Math.floor(position),
    verbose: true,
  },
});

export const setPosition = ({
  sessionId,
  chapterId,
  position,
}: PostSetPositionParams) => ({
  url: PostgrestEndpoints.SET_SESSION_POSITION,
  method: 'POST',
  body: {
    session_id: sessionId,
    chapter_id: chapterId,
    position: Math.floor(position),
    client_type: 'web',
    verbose: true,
  },
});

export const seekPosition = ({
  sessionId,
  chapterId,
  position,
  isInTestMode,
}: PostSeekPositionParams) => ({
  url: PostgrestEndpoints.SEEK_SESSION_POSITION,
  method: 'POST',
  body: {
    session_id: sessionId,
    chapter_id: chapterId,
    position: Math.floor(position),
    client_type: 'web',
    verbose: true,
    test_mode: isInTestMode,
  },
});

export const fetchPlayerSessions = () => ({
  url: PostgrestEndpoints.PLAYER_SESSIONS,
});

// TODO: pass answers into this requests once it starts doing validation
export const answerQuiz = ({
  sessionId,
  chapterId,
  quizId,
}: AnswerQuizPayload) => ({
  url: PostgrestEndpoints.ANSWER_QUIZ,
  method: 'POST',
  body: {
    session_id: sessionId,
    chapter_id: chapterId,
    quiz_id: quizId,
    // answers,
  },
});

export const claimPlayerSession = ({
  sessionId,
}: ClaimPlayerSessionPayload) => ({
  url: PostgrestEndpoints.CLAIM_PLAYER_SESSION,
  method: 'POST',
  body: {
    session_id: sessionId,
  },
});

export const submitPurchaseFeedback = ({
  orderId,
  heardAboutUs,
  otherText,
}: {
  orderId: number;
  heardAboutUs: string;
  otherText?: string;
}) => ({
  url: PostgrestEndpoints.SUBMIT_PURCHASE_FEEDBACK,
  method: 'POST',
  body: {
    order_id: orderId,
    heard_about_us_from: heardAboutUs,
    other_text: otherText,
  },
});

export const fetchRecommendedEpisodesRequest = (region: Region) => ({
  url: PostgrestEndpoints.RECOMMENDED_EPISODES,
  method: 'GET',
  params: {
    limit: 10,
    region: `eq.${region === 'CAN' ? 'canada' : 'usa'}`,
    select: EPISODE_CARD_REQUIRED_FIELDS.join(','),
  },
});

export const fetchRelatedEpisodesRequest = ({
  region,
  episodeId,
}: {
  region: Region;
  episodeId: string;
}) => ({
  url: PostgrestEndpoints.RELATED_EPISODES,
  method: 'GET',
  params: {
    current_episode_id: `eq.${episodeId}`,
    region: `eq.${region === 'CAN' ? 'canada' : 'usa'}`,
    select: EPISODE_CARD_REQUIRED_FIELDS.join(','),
    limit: 6,
  },
});

export const fetchOrganizationMembers = () => ({
  url: PostgrestEndpoints.ORGANIZATION_MEMBERS,
  method: 'GET',
});

export const reInviteOrganizationMembers = (
  organizationMemberIds: number[],
) => ({
  url: PostgrestEndpoints.REINVITE_ORGANIZATION_MEMBERS,
  method: 'POST',
  body: {
    organization_member_ids: organizationMemberIds,
  },
});

export const archiveOrganizationMembers = (
  organizationMemberIds: number[],
) => ({
  url: PostgrestEndpoints.ARCHIVE_ORGANIZATION_MEMBERS,
  method: 'POST',
  body: {
    organization_member_ids: organizationMemberIds,
  },
});

export const fetchOrganizationSettings = () => ({
  url: PostgrestEndpoints.ORGANIZATION_SETTING,
});
