import { MemberActivityTypes, MemberStates } from '@/constants';
import { OrganizationMember } from '@/types';
import { camelCaseKeys } from '@/utils';

export const transformOrganizationMember = (
  organizationMember: unknown,
): OrganizationMember => {
  const data = camelCaseKeys<OrganizationMember>(organizationMember);
  const { type, date } = getLastActivity(data);
  return {
    ...data,
    lastActivityType: type,
    lastActivityDate: date,
  } as OrganizationMember;
};

const getLastActivity = ({
  state,
  lastUserListening,
  lastInviteSentAt,
  stateUpdatedAt,
}: Pick<
  OrganizationMember,
  | 'lastUserListening'
  | 'lastInviteSentAt'
  | 'organizationMemberCreatedAt'
  | 'state'
  | 'stateUpdatedAt'
>) => {
  if (state === MemberStates.ACTIVE) {
    return {
      type: MemberActivityTypes.LISTENING,
      date: lastUserListening,
    };
  }
  if (state === MemberStates.PLAN_ASSIGNED) {
    return {
      type: MemberActivityTypes.INVITED,
      date: lastInviteSentAt,
    };
  }
  if (state === MemberStates.ARCHIVED || state === MemberStates.USER_DELETED) {
    return {
      type: MemberActivityTypes.REMOVED,
      date: stateUpdatedAt,
    };
  }
  return {
    type: MemberActivityTypes.NONE,
    date: null,
  };
};
