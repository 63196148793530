import { Box, Chip, Theme, Tooltip, useMediaQuery } from '@mui/material';

import { SingleSelectDropdown } from '@/components';
import { MemberFilters } from '@/constants';
import { MemberFilterKey, OrganizationMember } from '@/types';

export type Params = {
  data: OrganizationMember[] | undefined;
  activeFilter: MemberFilterKey;
  onFilterSelection: (filter: MemberFilterKey) => void;
};

const FilterChip = ({
  isActive,
  count,
  filter,
  label,
  tooltip,
  onFilterSelection,
}: {
  isActive: boolean;
  count?: number;
  filter: MemberFilterKey;
  label: string;
  tooltip?: string;
  onFilterSelection: (filter: MemberFilterKey) => void;
}) => (
  <Tooltip title={tooltip}>
    <Chip
      onClick={() => onFilterSelection(filter)}
      clickable
      color={isActive ? 'primary' : 'default'}
      variant={isActive ? 'filled' : 'outlined'}
      disabled={count === 0}
      sx={isActive ? { color: 'white', fontWeight: 'bold' } : {}}
      label={
        <>
          {count !== undefined ? (
            <>
              {label} <strong>{count}</strong>
            </>
          ) : (
            label
          )}
        </>
      }
    />
  </Tooltip>
);

export const MemberStateFilters = ({
  onFilterSelection,
  activeFilter,
  data,
}: Params) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  return isMobile ? (
    <Box mx={1}>
      <SingleSelectDropdown
        selectedOption={activeFilter}
        options={MemberFilters}
        onSave={value => onFilterSelection(value as MemberFilterKey)}
        title={'Show: '}
      />
    </Box>
  ) : (
    <Box mx={1} gap={1} display="flex" flexWrap="wrap">
      {(Object.keys(MemberFilters) as MemberFilterKey[]).map(key => (
        <FilterChip
          key={key}
          filter={key}
          label={MemberFilters[key].label}
          tooltip={MemberFilters[key].tooltip}
          isActive={activeFilter === key}
          count={
            data?.filter(m => MemberFilters[key].states.includes(m.state))
              .length
          }
          onFilterSelection={onFilterSelection}
        />
      ))}
    </Box>
  );
};
