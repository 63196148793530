import type { Theme } from '@mui/material';
import { Box, Tooltip, Typography, useMediaQuery } from '@mui/material';

import { Button } from '@/components';
import { MemberStates, Modals } from '@/constants';
import { AnalyticsService, EVENT_CONSTANTS, EVENTS } from '@/services';
import {
  uiActions,
  useAppDispatch,
  useReInviteOrganizationMembersMutation,
} from '@/store';
import { OrganizationMember } from '@/types';
import { SnackbarUtils } from '@/utils';

interface BulkEditProps {
  members: OrganizationMember[];
  setSelectedOrganizationMemberIds: React.Dispatch<
    React.SetStateAction<number[]>
  >;
  selectedOrganizationMemberIds: number[];
  isArchivingDisabled: boolean;
}
const location = {
  page: EVENT_CONSTANTS.PAGE.MEMBER_MANAGEMENT,
  component: EVENT_CONSTANTS.COMPONENT.BULK_EDIT_TOOLBAR,
};

export const BulkEdit = ({
  members,
  setSelectedOrganizationMemberIds,
  selectedOrganizationMemberIds,
  isArchivingDisabled,
}: BulkEditProps) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const [reinviteOrganizationMembers, { isLoading: isReinviteLoading }] =
    useReInviteOrganizationMembersMutation();
  const dispatch = useAppDispatch();
  const numSelected = selectedOrganizationMemberIds.length;

  const resendInvites = async () => {
    const validMemberIds = members
      .filter(
        member =>
          selectedOrganizationMemberIds.includes(member.organizationMemberId) &&
          member.state === MemberStates.PLAN_ASSIGNED,
      )
      .map(member => member.organizationMemberId);

    AnalyticsService.resendInvite({
      location: {
        ...location,
        button: EVENT_CONSTANTS.BUTTON.RESEND,
      },
      organizationMemberIds: validMemberIds || [],
    });

    if (!validMemberIds?.length) {
      SnackbarUtils.warning('Oops! Select at least 1 Invited member');
      return;
    }

    try {
      AnalyticsService.logEvent(EVENTS.MEMBER_MANAGEMENT_EVENTS.RESEND.INTENT);
      await reinviteOrganizationMembers(validMemberIds).unwrap();

      AnalyticsService.logEvent(EVENTS.MEMBER_MANAGEMENT_EVENTS.RESEND.SUCCESS);
      SnackbarUtils.success(
        validMemberIds.length > 1
          ? `${validMemberIds.length} new invitations have been sent!`
          : 'New invitation has been sent',
      );
    } catch (error) {
      AnalyticsService.logEvent(EVENTS.MEMBER_MANAGEMENT_EVENTS.RESEND.FAIL);
      SnackbarUtils.warning('Failed to resend invite');
    }
  };

  const archiveMembers = () => {
    AnalyticsService.removeMember({
      location: {
        ...location,
        button: EVENT_CONSTANTS.BUTTON.REMOVE,
      },
      organizationMemberIds: selectedOrganizationMemberIds,
    });

    dispatch(
      uiActions.setActiveModal({
        name: Modals.MEMBER_ARCHIVE,
        params: {
          organizationMemberIds: selectedOrganizationMemberIds,
        },
      }),
    );
  };

  return (
    <Box
      sx={styles.bulkActions}
      className={numSelected ? 'visible' : undefined}>
      {!isMobile && (
        <Box sx={styles.membersSelected}>
          <Typography variant="body3" textAlign="right">
            {numSelected} Selected:
          </Typography>
        </Box>
      )}

      <Button
        onClick={resendInvites}
        label="Resend Invites"
        fullWidth={false}
        disabled={isReinviteLoading}
      />
      <Tooltip
        arrow
        placement="top"
        title={
          isArchivingDisabled
            ? 'Members in your organization are managed by a third party integration.'
            : ''
        }>
        <Box>
          <Button
            disabled={isArchivingDisabled}
            onClick={archiveMembers}
            label="Remove"
            fullWidth={false}
          />
        </Box>
      </Tooltip>
      <Button
        onClick={() => {
          setSelectedOrganizationMemberIds([]);
        }}
        label="Deselect"
        fullWidth={false}
      />
    </Box>
  );
};

const styles = {
  bulkActions: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.white,
    borderRadius: '300px',
    maxHeight: 'fit-content',
    maxWidth: 'fit-content',
    gap: 1,
    py: 1,
    px: 1.5,
    // floating
    boxShadow: theme.shadows[6],
    position: 'fixed',
    bottom: `${theme.dimensions.miniPlayer.height + 60}px`,
    right: 0,
    left: { xs: '0px', md: '0px', lg: `${theme.dimensions.sidebar.width}px` },
    margin: '0 auto',
    zIndex: theme.zIndex.fab,
    //animation
    transition: 'all 0.2s ease-in-out',
    opacity: 0,
    visibility: 'hidden',
    transform: 'translateY(10px)',
    '&.visible': {
      opacity: 1,
      visibility: 'visible',
      transform: 'translateY(0)',
    },
  }),
  membersSelected: {
    minWidth: '80px', //avoid jitter
  },
};
