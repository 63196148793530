import { Close } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

import { Button } from '@/components';
import { AnalyticsService, EVENTS } from '@/services';
import {
  selectActiveModalParams,
  uiActions,
  useAppDispatch,
  useAppSelector,
  useArchiveOrganizationMembersMutation,
} from '@/store';
import { SnackbarUtils } from '@/utils';

export const MemberArchiveModalContent = () => {
  const dispatch = useAppDispatch();

  const [archiveOrganizationMember, { isLoading: isArchiveMembersLoading }] =
    useArchiveOrganizationMembersMutation();

  const { organizationMemberIds } =
    useAppSelector(selectActiveModalParams) || {};

  const memberCount = organizationMemberIds?.length;

  const onClose = () => dispatch(uiActions.closeActiveModal());
  const onArchive = async () => {
    try {
      AnalyticsService.logEvent(EVENTS.MEMBER_MANAGEMENT_EVENTS.REMOVE.INTENT);
      await archiveOrganizationMember(organizationMemberIds).unwrap();

      AnalyticsService.logEvent(EVENTS.MEMBER_MANAGEMENT_EVENTS.REMOVE.SUCCESS);
      SnackbarUtils.success(
        `${memberCount} member${memberCount > 1 ? 's' : ''} removed successfully`,
      );
      onClose();
    } catch (error) {
      AnalyticsService.logEvent(EVENTS.MEMBER_MANAGEMENT_EVENTS.REMOVE.FAIL);
      SnackbarUtils.error('Failed to remove member(s), please try again later');
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 4, gap: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h3">
          Remove{memberCount > 1 ? ` ${memberCount}` : ''} Member
          {memberCount > 1 && 's'}?
        </Typography>
        <Close onClick={onClose} sx={{ cursor: 'pointer' }} />
      </Box>
      <Typography variant="body2">
        Are you sure you want to remove{' '}
        {memberCount > 1 ? 'these members' : 'this member'} from your LumiQ
        plan?
      </Typography>
      <Typography variant="body2">
        They will still be able to access their professional development
        certificates, but won't be able to earn new ones.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        <Button
          variant="secondary"
          skinny={false}
          label="Cancel"
          onClick={onClose}
        />
        <Button
          variant="primary"
          skinny={false}
          label="Remove"
          disabled={isArchiveMembersLoading}
          onClick={onArchive}
        />
      </Box>
    </Box>
  );
};
