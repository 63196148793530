import {
  archiveOrganizationMembers,
  fetchOrganizationMembers,
  fetchOrganizationSettings,
  reInviteOrganizationMembers,
} from '@/requests';
import { postgrestApi } from '@/store/postgrest-api';
import { OrganizationMember, OrganizationSettings } from '@/types';
import { camelCaseKeys } from '@/utils';

import { PostgrestApiTags } from '../store.constants';
import { transformOrganizationMember } from './member-management-api.utils';

export const memberManagementApi = postgrestApi.injectEndpoints({
  endpoints: builder => ({
    fetchOrganizationMembers: builder.query<OrganizationMember[], null>({
      query: fetchOrganizationMembers,
      transformResponse: (response: unknown[]) =>
        response.map(transformOrganizationMember),
      providesTags: [PostgrestApiTags.ORGANIZATION_MEMBERS],
    }),
    fetchOrganizationSettings: builder.query<OrganizationSettings, void>({
      query: fetchOrganizationSettings,
      transformResponse: (response: any) => camelCaseKeys(response[0]),
    }),
    archiveOrganizationMembers: builder.mutation<boolean, number[]>({
      query: archiveOrganizationMembers,
      invalidatesTags: [PostgrestApiTags.ORGANIZATION_MEMBERS],
    }),
    reInviteOrganizationMembers: builder.mutation<boolean, number[]>({
      query: reInviteOrganizationMembers,
      transformResponse: (response: any) => response.data,
      invalidatesTags: [PostgrestApiTags.ORGANIZATION_MEMBERS],
    }),
  }),
});

export const {
  useReInviteOrganizationMembersMutation,
  useFetchOrganizationMembersQuery,
  useFetchOrganizationSettingsQuery,
  useArchiveOrganizationMembersMutation,
} = memberManagementApi;
