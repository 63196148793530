import { useSelector } from 'react-redux';
import { useFetchEpisodeChapterProgress } from 'src/hooks/useFetchEpisodeChapterProgress';

import {
  selectEpisodeProgress,
  selectEpisodeRegion,
  useAppSelector,
  useFetchEpisodeChaptersQuery,
} from '@/store';

import { EpisodeChapterList as EpisodeChapterListComponent } from './EpisodeChapterList.component';

export const EpisodeChapterList = ({ episode, referrer }) => {
  const { episodeId } = episode;

  const region = useAppSelector(state =>
    selectEpisodeRegion(state, episode?.episodeId),
  );

  const { data: chapters, isLoading: isChaptersLoading } =
    useFetchEpisodeChaptersQuery(
      { episodeId, region },
      {
        selectFromResult: result => ({
          ...result,
          data: result.data?.filter(r => !r.isTrailer),
        }),
        skip: !episodeId,
      },
    );

  /*
    Only triggers and signals the initial fetching
    the response data gets pushed to the EntityAdapter and selected via `selectEpisodeProgress`
  */
  const { isLoading: isUserChapterProgressLoading } =
    useFetchEpisodeChapterProgress(episodeId);
  /* Selects the chapter data from the EntityAdapter as it gets continuously updated for e.g. set_position */
  const episodeChaptersProgress = useSelector(state =>
    selectEpisodeProgress(state, episodeId),
  );

  if (isChaptersLoading || isUserChapterProgressLoading || !chapters?.length)
    return null;

  return (
    <EpisodeChapterListComponent
      chapters={chapters}
      episodeChaptersProgress={episodeChaptersProgress}
      referrer={referrer}
    />
  );
};
