import { Box, Typography } from '@mui/material';

import { CardFlair } from '@/components';
import { selectEpisodeRegion, useAppSelector } from '@/store';
import { convertSecondsToMinutes } from '@/utils';

export const EpisodeCoverImage = ({ episode }) => {
  const region = useAppSelector(state =>
    selectEpisodeRegion(state, episode?.episodeId),
  );
  const episodeDuration = convertSecondsToMinutes(episode.duration);

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: 212,
        overflow: 'hidden',
      }}>
      <Box
        component="img"
        src={episode.cover}
        alt="episode cover"
        sx={{
          borderRadius: 6,
          objectFit: 'cover',
          width: '100%',
          height: '100%',
        }}
      />
      {episode.episodeAgeTag === 'new' && (
        <CardFlair variant="large" label="NEW!" />
      )}
      {!!episode.companyLogo && (
        <Box
          component="img"
          src={episode.companyLogo}
          alt="company logo"
          sx={{
            position: 'absolute',
            bottom: 16,
            left: 16,
            borderRadius: 2,
            height: 60,
            width: 60,
          }}
        />
      )}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          borderBottomRightRadius: 24,
          backgroundColor: 'white',
          opacity: 0.9,
          px: 2,
          py: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {region === 'CAN' ? (
          <Box>
            <Typography variant="h2" color="teal30" lineHeight={1}>
              {episodeDuration}
            </Typography>
            <Typography variant="subtitle3" color="teal30">
              minutes
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography variant="h2" color="teal30" lineHeight={1}>
              {episode.CPEDetails?.credits}
            </Typography>
            <Typography variant="subtitle3" color="teal30">
              CPE Credits
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
